import React from 'react';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { Container, Row, Col } from 'react-bootstrap';

const Layout = ({children}) => {
    return (
        <Container fluid>
            <Row className='min-vh-100'>
                <Col sm={12} md={3} xl={2} className='p-0 vh-md-100 sticky-top'>
                    <Sidebar></Sidebar>
                </Col>
                <Col className='p-0'>
                    <Container className='pt-3'>
                        <main>{children}</main>
                    </Container>
                    {/* <Footer></Footer> */}
                </Col>
            </Row>
        </Container>
    );
};

export default Layout;