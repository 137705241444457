import React from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';


const GeneratorForm = (props) => {
    const [showModal, setShowModal] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({
        colorname: '',
        range2: 0,
        range1: 0,
        color: "#3275cd",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: check if form successfully
        setFormSubmitted(true);
        props.formInputCallback(formValues);
    };


    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please enter a value in the input field.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Card className='w-100 shadow-lg'>
                <Card.Header>Customize your suit</Card.Header>
                <ListGroup variant="flush">
                    <Form onSubmit={handleSubmit} className="mb-3">
                        <ListGroup.Item>
                            <Form.Group className="mb-3"  >
                                <Row>
                                    <Col className=''>
                                        <Form.Label >Suit Color</Form.Label>
                                    </Col>
                                    <Col className=''>
                                        <Form.Control type="text" name="colorname" value={formValues.colorname} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3"  >
                                <Row>
                                    <Col className=''>
                                        <Form.Label >Tie</Form.Label>
                                    </Col>
                                    <Col className=''>
                                        <Form.Check type="checkbox" label="Yes" name="tie" onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <Form.Group className="mb-3"  >
                                <Row>
                                    <Col className=''>
                                        <Form.Label >K.a</Form.Label>
                                    </Col>
                                    <Col className=''>
                                        <Form.Control
                                            type="color"
                                            id="exampleColorInput"
                                            title="Choose your color"
                                            name="color"
                                            value={formValues.color}
                                            onChange={handleInputChange}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3"  >
                                <Row>
                                    <Col className=''>
                                        <Form.Label >K.a</Form.Label>
                                    </Col>
                                    <Col className=''>
                                        <Form.Range name="range1" value={formValues.range1} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Row>
                                    <Col className=''>
                                        <Form.Label >K.a</Form.Label>
                                    </Col>
                                    <Col className=''>
                                        <Form.Range name="range2" value={formValues.range2} onChange={handleInputChange} />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <div className="d-grid">
                                <Button variant="primary" type="submit">
                                    Generate Image
                                </Button>
                            </div>
                        </ListGroup.Item>
                    </Form>
                </ListGroup>
            </Card>
        </>
    );
};

export default GeneratorForm;