import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Configuration, OpenAIApi } from "openai";
import { useState } from 'react';
import { useRef } from 'react';
import max from '../img/max.png';



const Test = () => {

    // ------------------ S3 Images Example ------------------
    const [image, setImage] = React.useState(null);
    const uploadToS3 = async () => {
        const result2 = await Storage.put(image.name, image, {
            level: "private",
        });
        console.log('File uploaded:', image);
    };

    // -------------------- OpenAI Generate Images --------------------
    const configuration = new Configuration({
        apiKey: "fdgdfsgdfs",
    });
    const openai = new OpenAIApi(configuration);
    const [result, setResult] = useState([]);
    const generateImage = async () => {
        const res = await openai.createImage({
            prompt: "man in a suit",
            n: 4,
            size: "512x512",
        });
        setResult(res.data.data);
        console.log(res.data.data[0].url);
    };

    // -------------------- Image Editing Example --------------------
    const coordinates = [{ x: 0, y: 0, width: 700, height: 700 }];
    const imageUrl = max;

    const canvasRef = useRef(null);
    const [editedImageUrl, setEditedImageUrl] = useState(null);

    const handleImageLoad = (image) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = image.width;
        console.log(image.width);
        canvas.height = image.height;
        console.log(image.height);
        ctx.drawImage(image, 0, 0);

        // Delete the specified area of the image
        coordinates.forEach(coord => {
            const imageData = ctx.getImageData(coord.x, coord.y, coord.width, coord.height);
            for (let i = 0; i < imageData.data.length; i += 4) {
                imageData.data[i + 3] = 0; // Set alpha value to 0 to make pixel transparent
            }
            ctx.putImageData(imageData, coord.x, coord.y);
        });

        // Export the edited image as a new PNG file
        const editedImage = canvas.toDataURL('image/png');
        setEditedImageUrl(editedImage);
    };

    const handleImageError = () => {
        console.error('Failed to load image');
    };

    const loadImage = () => {
        const myImage = new Image();
        myImage.src = imageUrl;
        myImage.onload = handleImageLoad(myImage);
        myImage.onerror = handleImageError;
    };


    // -------------------- OpenAI update Images / Not working!! --------------------
    const updateImage = async () => {
        console.log("update image");
        const res = await openai.createImageVariation({
            image: "https://cdn.openai.com/API/images/guides/image_generation_detailed.webp",
            n: 2,
            size: "1024x1024"});
        setResult(res.data.data);
        console.log(res.data.data[0].url);
    };



    return (
        <div>
            <h1 className='mb-3'>Pricing</h1>

            <Authenticator>
                <div className='justify-content-around align-items-center w-100 mb-5'>
                    <Card className=''>
                        <Card.Body>
                            <Card.Title>Test</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Test test</Card.Subtitle>
                            <Card.Text>

                                {image ? (
                                    <img src={URL.createObjectURL(image)} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                                ) : (
                                    <input
                                        type="file"
                                        onChange={(e) =>
                                            setImage(e.target.files[0])
                                        }
                                    />
                                )}
                                <Button onClick={uploadToS3}>Upload</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div >

                <div className='justify-content-around align-items-center w-100 mb-5'>
                    <Button onClick={generateImage}>Generate</Button>

                    <Row xs={1} md={6} className="rounded ms-1 me-1 mb-4 shadow-lg d-flex justify-content-around">
                        {
                            result.map((image) => (
                                <Col key={image.url} className='d-flex justify-content-center'>
                                    <img src={image.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                                </Col>
                            ))
                        }
                    </Row >
                </div>

                <div className='justify-content-around align-items-center w-100'>
                    <img src={imageUrl} rounded className=' mt-2 mb-2' style={{ maxHeight: '200px' }} />

                    <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                    {editedImageUrl ? (
                        <>
                            <img src={editedImageUrl} alt="Edited Image" style={{ maxHeight: '200px' }} />
                            <Button onClick={updateImage}>Generate</Button>
                        </>
                    ) : (
                        <button onClick={loadImage}>cute image</button>
                    )}

                </div>

            </Authenticator>
        </div >
    );
};

export default Test;