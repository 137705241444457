import React from 'react';

const Logout = () => {
    return (
        <div>
            <h1>Logged out</h1>
        </div>
    );
};

export default Logout;