import React from 'react';
import { Container } from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { Authenticator } from '@aws-amplify/ui-react';
import { Alert } from 'react-bootstrap';
import { Button, Form, Stack } from 'react-bootstrap';

const Gallery = () => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        getFilesFromS3();
    }, []);


    const getFilesFromS3 = async () => {
        try {
            // get the list of files
            const result = await Storage.list('', { level: 'private' });
            console.log("S3 content:", result.results);

            // get the signed URL string of every file
            const files = [];
            for (let i = 0; i < result.results.length; i++) {
                const item = result.results[i];
                const fileUrl = await Storage.get(item.key, { level: 'private' });
                files.push({ key: item.key, url: fileUrl });
            }
            console.log("Image links:", files);
            setFiles(files);

        } catch (error) {
            setFiles([]);
        }
    };

    return (
        <div>
            <h1 className='mb-3'>Gallery</h1>

            <Alert variant="success" dismissible>
                <Alert.Heading>Hey, nice to see you</Alert.Heading>
                <p>
                    Aww yeah, you successfully read this important alert message. This
                    example text is going to run a bit longer so that you can see how
                    spacing within an alert works with this kind of content.
                </p>
                <hr />
                <p className="mb-0">
                    Whenever you need to, be sure to use margin utilities to keep things
                    nice and tidy.
                </p>
            </Alert>

            <div>
                <Authenticator>
                    {files.map((file) => (
                        <Row xs={1} md={6} key={file.key} className="rounded ms-1 me-1 mb-4 shadow-lg d-flex justify-content-around">
                            <Col className='d-flex justify-content-center'>
                                <Image src={file.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                            </Col>
                            <Col md={1} className='d-flex justify-content-center'>
                                <div className="vr" />
                            </Col>
                            <Col  className='d-flex justify-content-center'>
                                <Image src={file.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <Image src={file.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <Image src={file.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <Image src={file.url} rounded className='img-fluid mt-2 mb-2' style={{ maxHeight: '200px' }} />
                            </Col>
                        </Row>
                    ))}

                </Authenticator>
            </div >
        </div>
    );
};

export default Gallery;