import React from 'react';
import { Container } from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import { Authenticator } from '@aws-amplify/ui-react';
import max from '../img/max.png';
import { Button, Form, Stack } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import Footer from '../components/Footer';


const Result = () => {
    return (
        <div>
            <h1 className='mb-3'>Generate</h1>

            <Alert variant="success"  dismissible>
                <Alert.Heading>Hey, nice to see you</Alert.Heading>
                <p>
                    Aww yeah, you successfully read this important alert message. This
                    example text is going to run a bit longer so that you can see how
                    spacing within an alert works with this kind of content.
                </p>
                <hr />
                <p className="mb-0">
                    Whenever you need to, be sure to use margin utilities to keep things
                    nice and tidy.
                </p>
            </Alert>

            <div className='mt-3'>
                <Authenticator>
                    <Row >
                        <Col className='d-flex justify-content-center bg-white rounded'>
                            <Image src={max} rounded className='img-fluid mt-2 mb-2 border border-secondary shadow-lg'  />
                        </Col>
                        <Col md={1} className='d-flex justify-content-center'>
                            <div className="vr" />
                        </Col>
                        <Col  className='d-flex justify-content-center'>
                            <Row xs={1} md={2}>
                                <Col className='d-flex justify-content-center' >
                                    <Image src={max} rounded className='img-fluid mt-2 mb-2 border border-secondary shadow-lg' />
                                </Col>
                                <Col className='d-flex justify-content-center' >
                                    <Image src={max} rounded className='img-fluid mt-2 mb-2 border border-secondary shadow-lg' />
                                </Col>
                                <Col className='d-flex justify-content-center' >
                                    <Image src={max} rounded className='img-fluid mt-2 mb-2 border border-secondary shadow-lg'  />
                                </Col>
                                <Col className='d-flex justify-content-center' >
                                    <Image src={max} rounded className='img-fluid mt-2 mb-2 border border-secondary shadow-lg'/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Authenticator>
            </div >
        </div >
    );
};

export default Result;