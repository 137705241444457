import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';

import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';


const Login = () => {
    return (
        <div>
                <Authenticator>
                    <Row className='justify-content-around align-items-center'>
                        <Col sm={12} md={4} >
                            <Card className="shadow-lg">
                                <Card.Body>
                                    <Card.Title>Hi XXX!</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.

                                        dddddddd

                                        {({ signOut, user }) => (
                                            <main>
                                                <h1>Hello {user.username}</h1>
                                                <button onClick={signOut}>Sign out</button>
                                            </main>
                                        )}

                                    </Card.Text>
                                    <Card.Link href="#">Card Link</Card.Link>
                                    <Card.Link href="#">Another Link</Card.Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Authenticator>
        </div >
    );
};

export default Login;