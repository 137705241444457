import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Configuration, OpenAIApi } from "openai";
import { useState } from 'react';
import { useRef } from 'react';
import max from '../img/max.png';
import { Lambda } from 'aws-sdk';


const TestLambda = () => {
    const [lambdaResponse, setLambdaResponse] = useState(null);

    const callLambdaFunction = async () => {
      try {
        const credentials = await Auth.currentCredentials();
  
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: 'eu-central-1'
        });
  
        const params = {
          FunctionName: 'openai-staging',
          Payload: JSON.stringify({ prompt: 'cat' })
        };
  
        const response = await lambda.invoke(params).promise();
  
        console.log(JSON.parse(response.Payload));
        console.log("body:", JSON.parse(JSON.parse(response.Payload).body));
        setLambdaResponse(JSON.parse(JSON.parse(response.Payload).body));
      } catch (error) {
        console.error(error);
      }
    };
  
    return (
        <Authenticator>
      <div>
        <button onClick={callLambdaFunction}>Call Lambda Function</button>
        {lambdaResponse && <div> <img src={lambdaResponse.imageUrl} alt="Edited Image" style={{ maxHeight: '200px' }}    onError={(e) => console.log(e)}/></div>}
      </div>
      </Authenticator>
    );
};

export default TestLambda;