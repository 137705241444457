import React from 'react';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import ReactLassoSelect, { getCanvas } from 'react-lasso-select';
import { useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Alert } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import GeneratorForm from '../components/GeneratorForm';
import GeneratorLasso from '../components/GeneratorLasso';
import { useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';


const Generate = () => {
    const [formData, setFormData] = React.useState(null);

    const formInputCallback = (formInput) => {
        console.log("Form Values:", formInput);
        setFormData(formInput);
    };

    return (
        <div>
            <h1 className='mb-3'>Generate</h1>

            <Alert variant="success" dismissible>
                <Alert.Heading>Hey, nice to see you</Alert.Heading>
                <p>
                    Aww yeah, you successfully read this important alert message. This
                    example text is going to run a bit longer so that you can see how
                    spacing within an alert works with this kind of content.
                </p>
                <hr />
                <p className="mb-0">
                    Whenever you need to, be sure to use margin utilities to keep things
                    nice and tidy.
                </p>
            </Alert>

            <div>
                <Authenticator>

                    <Row>

                        {formData ? (
                            <>
                                <Col sm={12} md={12} className='shadow-lg rounded bg-secondary d-flex justify-content-center align-items-center h-100' style={{ minHeight: '200px' }}>
                                <Triangle
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    ariaLabel="triangle-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col sm={12} md={8} className="mb-3">
                                    <GeneratorLasso />
                                </Col>
                                <Col sm={12} md={4} className="mb-3">
                                    <GeneratorForm formInputCallback={formInputCallback} />
                                </Col>
                            </>)}

                    </Row>
                </Authenticator>
            </div >
        </div >
    );
};

export default Generate;