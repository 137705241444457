import React from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Pricing = () => {
    return (
        <div>
            <h1 className='mb-3'>Pricing</h1>
            <Row className="d-flex justify-content-around">
                <Col sm={12} md={3} className="mb-3">
                    <Card className="shadow-lg">
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                            <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text>
                            <Card.Link href="#">Card Link</Card.Link>
                            <Card.Link href="#">Another Link</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={3}  className="mb-3">
                    <Card className="shadow-lg">
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                            <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text>
                            <Card.Link href="#">Card Link</Card.Link>
                            <Card.Link href="#">Another Link</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={3}  className="mb-3">
                    <Card className="shadow-lg"> 
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                            <Card.Text>
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                                Some quick example text to build on the card title and make up the
                                bulk of the card's content.
                            </Card.Text>
                            <Card.Link href="#">Card Link</Card.Link>
                            <Card.Link href="#">Another Link</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </div >
    );
};

export default Pricing;