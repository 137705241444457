import React, { useState } from 'react';
import {
    FaTh,
    FaBars,
    FaShare,
    FaSignInAlt,
    FaMoneyBillWave,
} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { Nav, Dropdown, Collapse, Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { Hub } from 'aws-amplify';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    async function checkAuth() {
        try {
            await Auth.currentAuthenticatedUser();
            setIsLoggedIn(true);
        } catch (error) {
            setIsLoggedIn(false);
        }
    }

    useEffect(() => {
        // check if user is logged in
        checkAuth();

        // listens if user logs in or out
        Hub.listen('auth', (data) => {
            const event = data.payload.event;
            if (event === 'signIn') {
                setIsLoggedIn(true);
            } else if (event === 'signOut') {
                setIsLoggedIn(false);
            }
        });
    }, []);

    function navItem(to, icon, text, onClick = null) {
        return (
            <Nav.Item>
                <NavLink to={to} onClick={onClick} className='nav-link text-white d-flex align-items-center'>
                    <div className='d-flex me-2'>
                        {icon}
                    </div>
                    {text}
                </NavLink>
            </Nav.Item>
        )
    }

    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark h-md-100">
            <div className='d-flex'>
                <a href="/" className="d-flex flex-grow-1 align-items-center text-white text-decoration-none">
                    <span className="fs-4">SuitMeUP</span>
                </a>
                <Button variant='dark' onClick={toggle} className='d-md-none'><FaBars></FaBars></Button>
            </div>
            <Collapse in={isOpen} className='h-md-100'>
            <div>
            <div className='d-flex flex-column h-md-100'>
            <hr />
            <Nav className='nav-pills flex-column mb-auto'>
                {navItem("/", <FaTh />, "Home")}
                {navItem("/signup", <FaShare />, "Start Now")}
                {navItem("/pricing", <FaMoneyBillWave />, "Pricing")}
                {isLoggedIn && (
                    <>
                        <hr />
                        {navItem("/generate", <FaSignInAlt />, "Generate")}
                        {navItem("/gallery", <FaSignInAlt />, "Gallery")}
                        {navItem("/result", <FaSignInAlt />, "Result")}
                    </>
                )}
            </Nav>
            <hr />
            {isLoggedIn ?
                <Dropdown>
                    <Dropdown.Toggle className='text-white text-decoration-none' variant='link'>
                        test.sd@email.com
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant='dark'>
                        <Dropdown.Item>
                            <NavLink to="/logout" onClick={signOut} className='nav-link text-white d-flex align-items-center'>
                                <div className='d-flex me-2'>
                                    <FaSignInAlt />
                                </div>
                                Sign Out
                            </NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                :
                <Nav className='nav-pills flex-column'>
                    {navItem("/login", <FaSignInAlt />, "Login")}
                </Nav>
            }
            </div>
            </div>
            </Collapse>
        </div>
    );
};

export default Sidebar;