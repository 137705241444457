import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard.jsx';
import Pricing from './pages/Pricing.jsx';
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import Generate from './pages/Generate';
import SignUp from './pages/SignUp';
import Test from './pages/Test';
import Logout from './pages/Logout';
import Result from './pages/Result';
import TestLambda from './pages/Test_lambda';

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/generate" element={<Generate />} />
          <Route path="/testlambda" element={<TestLambda />} />
          <Route path="/test" element={<Test />} />
          <Route path="/result" element={<Result />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;