import React from 'react';
import ReactLassoSelect, { getCanvas } from 'react-lasso-select';
import { useState } from 'react';


const GeneratorLasso = () => {
    const [image, setImage] = React.useState(null);
    const [points, setPoints] = useState([]);
    const [clippedImg, setClippedImg] = useState();

    return (
        <div className='shadow-lg rounded bg-secondary d-flex justify-content-center align-items-center h-100' style={{ minHeight: '200px' }}>
            {image ? (
                <ReactLassoSelect
                    value={points}
                    src={image}
                    imageStyle={{ maxHeight: `400px` }}
                    onChange={value => {
                        setPoints(value);
                    }}
                    onComplete={value => {
                        if (!value.length) return;
                        getCanvas(image, value, (err, canvas) => {
                            if (!err) {
                                setClippedImg(canvas.toDataURL());
                            }
                        });
                    }}
                />
            ) : (
                <input
                    type="file"
                    onChange={(e) =>
                        setImage(URL.createObjectURL(e.target.files[0]))
                    }
                />
            )}
        </div>
    );
};

export default GeneratorLasso;